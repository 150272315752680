package dev.ayupi.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import dev.ayupi.styles.SocialLinkStyle
import dev.ayupi.util.Constants.GITHUB_URL
import dev.ayupi.util.Constants.INSTAGRAM_URL
import dev.ayupi.util.Constants.LINKEDIN_URL
import org.jetbrains.compose.web.css.px

@Composable
fun SocialBar(row: Boolean = false){
    if(row) {
        Row(
            modifier = Modifier
                .margin(top = 25.px)
                .padding(leftRight = 25.px)
                .minHeight(40.px)
                .backgroundColor(Colors.White)
                .borderRadius(r = 20.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            SocialLinks(row)
        }
    } else {
        Column(
            modifier = Modifier
                .margin(right = 25.px)
                .padding(topBottom = 25.px)
                .minWidth(40.px)
                .backgroundColor(Colors.White)
                .borderRadius(r = 20.px),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SocialLinks(row)
        }
    }

}

@Composable
private fun SocialLinks(
    row: Boolean = false
) {
    Link(
        path = GITHUB_URL,
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ){
        FaGithub(
            size = IconSize.LG,
            modifier =  SocialLinkStyle.toModifier()
                .margin(bottom = if(row) 0.px else 40.px, right = if(row) 40.px else 0.px)
        )
    }
    Link(
        path = LINKEDIN_URL,
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ){
        FaLinkedin(
            size = IconSize.LG,
            modifier = SocialLinkStyle.toModifier()
                .margin(bottom = if(row) 0.px else 40.px, right = if(row) 40.px else 0.px)

        )
    }
    Link(
        path = INSTAGRAM_URL,
        openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
    ){
        FaInstagram(
            size = IconSize.LG,
            modifier = SocialLinkStyle.toModifier()
        )
    }
}