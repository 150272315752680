package dev.ayupi.util

object Res {
    object Image {
        const val BACKGROUND = "background.svg"
        const val LOGO = "logo.svg"
        const val MAIN_IMG = "sascha_colored.png"
        const val ABOUT_IMG = "about.png"
        const val PENGUINIDOO = ""
    }

    object Icon {
        const val ANDROID = "androidicon.png"
        const val SOFTWARE_TESTER = "softwaretester.png"
        const val UI_UX_DESIGN = "uiux.png"
        const val OPEN_IN_NEW = "open_in_new.svg"

        const val USER = "user5.svg"
        const val SHIELD = "shield.svg"
        const val HAPPY = "happy.svg"
        const val CHECKMARK = "checkmark.svg"

        const val TWITTER_X = "xtwitter.svg"
    }

}