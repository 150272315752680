package dev.ayupi.models

import dev.ayupi.util.ExperienceConstants

enum class Experience(
    val number: String,
    val jobPosition: String,
    val description: String,
    val company: String,
    val from: String,
    val to: String,
) {
    First(
        number = "01",
        jobPosition = "Freelancer - App Developer",
        description = ExperienceConstants.FIRST,
        company = "ayupi.dev",
        from = "January 2019",
        to = "NOW",
    ),
    Second(
        number = "02",
        jobPosition = "Certified Softwaretester ISTQB",
        description = ExperienceConstants.SECOND,
        company = "iSQI Group",
        from = "August 2023",
        to = "September 2023",
    ),
    Third(
        number = "03",
        jobPosition = "Mobile App Developer - iOS",
        description = ExperienceConstants.THIRD,
        company = "Alfatraining GmbH",
        from = "July 2023",
        to = "August 2023",
    ),
    Fourth(
        number = "05",
        jobPosition = "Mobile App Developer - Android",
        description = ExperienceConstants.FOURTH,
        company = "Alfatraining GmbH",
        from = "June 2023",
        to = "July 2023",
    ),
    Fifth(
        number = "06",
        jobPosition = "Java Developer",
        description = ExperienceConstants.FIFTH,
        company = "Alfatraining GmbH",
        from = "April 2023",
        to = "June 2023",
    ),
    Six(
        number = "07",
        jobPosition = "IT Trainer",
        description = ExperienceConstants.SIX,
        company = "GWAB Wetzlar",
        from = "February 2018",
        to = "December 2020",
    ),
    Seven(
        number = "08",
        jobPosition = "IT-Trainer / App Developer",
        description = ExperienceConstants.SEVEN,
        company = "Wetzlarer Arbeitsloseninitiative e.V.",
        from = "June 2011",
        to = "November 2017",
    ),
    Eight(
        number = "09",
        jobPosition = "Specialist for IT Applications",
        description = ExperienceConstants.EIGHT,
        company = "Bundesagentur für Arbeit",
        from = "August 2007",
        to = "February 2010",
    ),

}