package dev.ayupi.models

enum class Portfolio(
    val image: String,
    val title: String,
    val description: String,
    val url: String
) {
    Penguidoo(
        image = "penguidoo_app_snapshot.png",
        title = "Penguidoo - Your life, organized",
        description = "Android Mobile App",
        url = "https://play.google.com/store/apps/details?id=dev.ayupi.penguidoo"
    ),
    IrregularPenguinKTX(
        image = "irregular_penguin_snapshot.png",
        title = "Irregular Penguin",
        description = "Java LibGDX Game",
        url = "https://github.com/aYudizzle/IrregularPenguin"
    ),
    PenguinDirectory(
        image = "pdir.png",
        title = "Penguin Director(y)",
        description = "Windows App",
        url = "https://github.com/aYudizzle/penguin-directory"
    ),
}