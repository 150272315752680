package dev.ayupi.util

object Constants {
    const val SECTION_WIDTH = 1920
    const val FONT_FAMILY = "Roboto"

    const val GITHUB_URL = "https://github.com/aYudizzle"
    const val INSTAGRAM_URL = "https://www.instagram.com/ayusician/"
    const val LINKEDIN_URL = "https://www.linkedin.com/in/sascha-neul"

    const val WELCOME_TEXT = "a passionate app developer specializing in Java and Kotlin. Welcome to my website, where innovation and creativity converge with code. Join me on this digital journey as we explore the exciting possibilities of app development and create solutions that inspire users!"
    const val ABOUT_TEXT = "I'm an app developer fluent in Java and Kotlin. My commitment to Clean Code and Clean Architecture ensures that your project is not just a piece of software but an elegant solution. Specializing in modern app development, I bring a wealth of experience in implementing design patterns that enhance scalability and maintainability. Let's collaborate to build not just apps, but finely crafted digital experiences that stand the test of time."
}

object ExperienceConstants {
    const val FIRST = "As a Freelancer specializing in Java, Kotlin, Android, and Spring, I've been crafting tailored mobile solutions since 2019. From ideation to deployment, I excel in developing dynamic, scalable applications. Collaborating closely with clients, I ensure a deep understanding of their business needs to deliver customized and efficient solutions. Staying at the forefront of Java, Kotlin, Android, and Spring technologies allows me to consistently provide innovative and future-proof (mobile) applications."
    const val SECOND = "During the period from August to September 2023, I undertook and successfully completed the International Software Testing Qualifications Board (ISTQB) certification. This comprehensive certification equipped me with a robust understanding of industry-standard software testing practices, methodologies, and principles. The training covered a wide range of topics, including test design, execution, and management, ensuring a well-rounded skill set in software testing. This certification not only validates my expertise but also underscores my commitment to maintaining high-quality standards in software development and testing."
    const val THIRD = "During the period, I completed certification as an iOS App Developer. This specialized program equipped me with in-depth knowledge of iOS development, including Swift programming language, UIKit/SwiftUI framework, and best practices for creating high-quality and user-friendly iOS applications. This certification reflects my commitment to excellence in iOS app development and showcases my skills in crafting innovative and seamless user experiences for the iOS platform."
    const val FOURTH = "In the span of June to July 2023, I successfully completed the certification process to become a Mobile App Developer specializing in Android. This intensive program covered key aspects of Android app development, including Java/Kotlin programming, Android Studio, and effective utilization of Android SDK. This certification demonstrates my expertise in creating feature-rich and performance-optimized mobile applications for the Android platform, emphasizing my commitment to delivering top-notch solutions in the dynamic field of mobile development."
    const val FIFTH = " I obtained certification as a Java Developer. This comprehensive program honed my proficiency in Java programming language, emphasizing best practices, design patterns, and advanced concepts. This certification validates my expertise in Java development and highlights my dedication to delivering high-quality, scalable, and efficient solutions using the Java programming language."
    const val SIX = "In my role as an IT Trainer in adult education from February 2018 to December 2020, I conducted training sessions focusing on Microsoft Office applications and promoting secure internet usage. I designed and delivered comprehensive courses aimed at enhancing participants' skills in using MS Office tools effectively. Additionally, I emphasized best practices for ensuring a secure online environment, empowering learners with the knowledge to navigate the internet safely. This experience strengthened my ability to facilitate engaging and informative training sessions, fostering digital literacy and IT proficiency among adult learners."
    const val SEVEN = "In my dual role as an IT Trainer and App Developer from June 2011 to November 2017, I conducted training sessions on MS Office and promoted internet safety. Simultaneously, I actively contributed to the redevelopment of the organization's online presence, taking a hands-on approach to programming and design. This multifaceted experience allowed me to not only impart essential IT skills but also directly contribute to enhancing the digital landscape through web development initiatives."
    const val EIGHT = "As a Specialist for IT Applications during this period, I served as a developer for tools designed to support the Service Centers of the Federal Employment Agency. Additionally, I played a key role as an in-house program specialist, overseeing and managing specific programs critical to the organization's operations. This experience allowed me to contribute directly to the optimization and efficiency of service delivery within the context of a government agency."
}