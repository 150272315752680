package dev.ayupi.models

import dev.ayupi.util.Res

enum class Service(
    val icon: String,
    val iconDescription: String,
    val title: String,
    val description: String,
) {
    Development(
        icon = Res.Icon.ANDROID,
        iconDescription = "android icon",
        title = "App Development",
        description = "I excel in crafting robust and user-centric mobile applications, leveraging my proficiency in Java and Kotlin. From concept to deployment, I am dedicated to delivering high-quality, functional, and innovative solutions that meet your app development needs."
    ),
    UI(
        icon = Res.Icon.UI_UX_DESIGN,
        iconDescription = "ui/ux icon",
        title = "UI/UX Design",
        description = "Bringing a keen eye for design, I specialize in creating intuitive and visually appealing user interfaces. My expertise in UI/UX design ensures that your applications not only function seamlessly but also provide a delightful and engaging user experience."

    ),
    Testing(
        icon = Res.Icon.SOFTWARE_TESTER,
        iconDescription = "softwaretester icon",
        title = "Software Testing",
        description = "I take pride in ensuring your apps operate seamlessly. Leveraging powerful tools such as JUnit and Espresso, I delve into the details of your code to catch bugs and guarantee that your software not only functions but excels. Let's collaborate to optimize your apps for a flawless user experience."
    )
}