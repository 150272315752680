package dev.ayupi.models

import dev.ayupi.util.Res

enum class Achievement(
    val icon: String,
    val number: Int,
    val description: String,
) {
    Completed(
        icon = Res.Icon.CHECKMARK,
        number = 10,
        description = "Completed Projects"
    ),
    Active(
    icon = Res.Icon.SHIELD,
    number = 3,
    description = "Active Projects"
    ),
//    Satisfied(
//    icon = Res.Icon.HAPPY,
//    number = 3,
//    description = "Satisfied Clients"
//    ),
    Team(
    icon = Res.Icon.USER,
    number = 1,
    description = "Team Members"
    )
}